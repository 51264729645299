.full-catalogue {
  display: flex;
  padding: 0;
  background-color: white;
}

.filters {
  width: 20%;
  margin-right: 20px;
}

.products-grid-container {
  background-color: #f8f9fa;
  border-radius: 10px;
}

.products-grid-container {
  padding: 20px;
}

.products-grid-heading {
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: left; 
}

.products-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 40px; /* Adjust the gap between products */
}

/* Filters component */
.filters {
  font-family: Arial, sans-serif;
  text-align: left;
  background-color: #f8f9fa; /* Matches the background color for the filter section */
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #343a40;
}

/* Filter Section */
.filter-section {
  margin: 0;
}

/* Brands */
.brand-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}

.brand-label input {
  margin-right: 10px;
}