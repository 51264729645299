

  .featured-product-card {
    padding: 0.8rem;
    max-width: 100%; /* Ensure it fits within the container */
    box-sizing: border-box;
  }


  .featured-product-card {
    text-align: left;
    width: auto; 
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    background-color: #fff;
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    height: 420px;
  }

  .featured-product-card-container {
    display: flex;
    overflow-x: auto;
    gap: 1.5vw;
    padding: 2vw 0;
    white-space: nowrap;
  }
  



  @media (max-width: 650px) {



  .featured-product-card {
    padding: 0.8rem;
    max-width: 100%; /* Ensure it fits within the container */
    box-sizing: border-box;
  }


  .featured-product-card {
    text-align: left;
    width: auto; 
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    background-color: #fff;
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    height: 420px;
  }

  .featured-product-card-container {
    display: flex;
    overflow-x: auto;
    gap: 0.5rem;
    margin-left: -6px;
    padding: 2vw 0;
    white-space: nowrap;
  }
  

  
}