.breadcrumb {
  width: 85vw;
  margin: 2vh auto;
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #8c8c8c;
}

.breadcrumb-link {
  text-decoration: none;
  color: #5e6e78;
}

.breadcrumb-link:hover {
  color: #32317d;
}

.breadcrumb-separator {
  margin: 0 5px;
  color: #8c8c8c;
}

.breadcrumb-current {
  color: #5e6e78;
  text-transform: capitalize;
}
