.nav {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    background: #f8f9fa;
    padding: 10px 0;
}

.nav__searchbar {
    width: 50%;
}

.maxWidth {
    max-width: 1500px;
    margin: auto;
    padding: 10px 4px;
} 

.hamburger {
    display: none;
}

.hamburger__ham {
    display: block;
    width: 100%;
    height: 2px;
    background: #4f4c4c;
}

.hamburger__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}


.mobileNav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #41413ae0;
    z-index: 100;
}

.mobileNav__nav {
    height: 100%;
    width: 78%;
    background: #f8f9fa;

}

.mobileNav__nav--header {
    background: #232f3e;
    padding: 73px 17px 15px;
    margin-bottom: .6rem;

}

.mobileNav__nav--header .mobileNav__secName {
   font-size: 1.3rem;
   line-height: 15px;
}

.mobileNav__nav--content h6{
    font-size: 1.1rem;
    padding: 5px 10px 5px;
    font-weight: 600;
    margin: 0;
}

.mobileNav__nav--content h6 a {
    text-decoration: none;
    color: inherit;
}

.mobileNav__nav--content {
    padding: 10px;

}

.mobileNav__nav--content .apart {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobileNav__nav--content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-weight: 500;
    margin-top: 4px;
}

.mobileNav__nav--content li {
    padding: 8px 10px;
    font-size: .9rem;
}

.mobileNav__nav--content li button{
    border: none;
    background: transparent;
    width: 100%;
    height: 100%;
    text-align: left;
    font-size: .9rem;
    padding: 0;
}

.mobileNav__nav--content li:hover {
  background-color: #d2d8dd;
}

.mobileNav__nav--cancel {
    position: absolute;
    top: 10px;
    right: 10px;
}

.mobileNav__nav--cancel button{
    padding: 0px 8px;
    font-size: 30px;
    background: none;
    color: #fff;
    border: none

}

.mobileNav__nav--header a {
    text-decoration: none;
}
hr{
    margin: 0.5rem 0px;
    height: 5px;
    background-color: black;
    border: none;
    opacity: 0.1;
}

.mobileNav__nav--header p {
    color: #fff;
    margin: 0;
    font-weight: 600;
    font-size: 1.8rem;
}

.nav__signin {
    display: none;
}
@media (max-width: 1000px) {
    .nav {
        padding: 8px 10px;
    }


    .nav__right {
      display: none;
    }

    .nav__searchbar {
        width: 60%;
    }

    .hamburger {
        display: inline-block;
        width: 40px;
        height: 23px;
        border: none;
        background: transparent;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
    }

  }

  @media (max-width: 650px) {
    .nav {
        flex-wrap: wrap;
        justify-content: unset;
        padding: 10px 10px;
    }

    .nav__signin {
        display: block;
        position: absolute;
        top: 23px;
        right: 44px;
        font-size: .8rem;
        font-weight: 500;
    }

    .nav__signin button {
        position: relative;
        border: none;
        background: none;
    }

    .nav__signin .nav__signin_login {
        display: inline-block;
        font-size: 1.8rem;
        position: absolute;
        top: -13px;
        left: 47px;
    }

    .nav__searchbar {
        width: 100%;
        margin: 16px auto 10px;
    }

}  