.about-us-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.8;
    color: #333;
  }
  
  .about-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .about-header h1 {
    font-size: 2.5rem;
    color: #222;
  }
  
  .about-content h2 {
    font-size: 1.8rem;
    color: #444;
    margin-top: 20px;
  }
  
  .about-content p {
    font-size: 1rem;
    margin-bottom: 15px;
    color: #555;
  }
  
  .about-content ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 15px;
  }
  
  .about-content ul li {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .about-content b {
    color: #444;
    font-weight: bold;
  }
  