.product-page {
  margin-top: 5vh;
  margin-bottom: 5vh;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.product-page-top {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1fr;
  gap: 20px;
  height: 80vh; /* Adjust height to be flexible */
  overflow: hidden;
}

.product-images {
  grid-column: 1 / 2;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%; /* Adjust height to be flexible */
}

.main-image {
  width: 100%;
  height: 80%; 
  background-color: #FFFFFF;
  border-radius: 10px;
  margin-bottom: 10px;
}

.alt-images-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto; /* Adjust height to be flexible */
  position: relative;
}

.scroll-button {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  height: auto; /* Adjust height to be flexible */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
}

.alt-images {
  display: flex;
  overflow-x: auto;
  height: auto; /* Adjust height to be flexible */
  margin: 0 40px;
  align-items: center;
  scroll-behavior: smooth;
  padding: 0 10px;
}

.alt-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 10px;
  cursor: pointer;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.product-info {
  grid-column: 2 / 3;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  overflow-y: auto; /* Make scroll behavior smoother */
  max-height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.product-info::-webkit-scrollbar {
  width: 0;
}
.product-info {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.product-name {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.2;
  word-wrap: break-word;
  text-align: left;
  white-space: normal;
  overflow: visible;
  width: 100%;
}

.section-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
}

.specifications {
  margin-top: 20px;
}

.specifications-table {
  width: 100%;
  border-collapse: collapse;
}

.specifications-table td {
  padding: 5px 10px;
  vertical-align: top;
}

.specification-key {
  font-weight: bold;
  text-align: left;
}

.specification-value {
  text-align: left;
}

.key-features {
  margin-top: 20px;
}

.product-seller {
  grid-column: 3 / 4;
  background-color: #F2F2F2;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 80%;
}

.seller-title {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.seller-details {
  margin-bottom: 20px;
}

.seller-detail-item {
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.seller-detail-item strong {
  font-weight: bold;
}

.seller-btn {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-seller-btn, .see-other-sellers-btn {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #32317d;
  color: white;
  cursor: pointer;
}

.contact-seller-btn:hover, .see-other-sellers-btn:hover {
  background-color: #413f6a;
}

.product-description {
  padding: 20px;
  background-color: #F2F2F2;
  border-radius: 10px;
  color: #333;
  margin-top: 20px;
}

.description-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.seller-list-modal .modal-dialog {
  max-width: 50px;
  width: 90%;
}

.seller-list-item {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .product-page-top {
    grid-template-columns: 1fr; /* Stack columns vertically */
    grid-template-rows: auto auto auto;
  }
  
  .product-info {
    grid-column: 1 / -1; /* Span full width */
  }
  
  .product-seller {
    grid-column: 1 / -1; /* Span full width */
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .product-page-top {
    display: flex; /* Use flex to stack sections vertically */
    flex-direction: column; /* Stack items in a column */
    height: auto; /* Allow height to be dynamic */
  }
  
  .product-images {
    order: 1; /* Product image section comes first */
    margin-bottom: 20px; /* Add space below */
  }
  
  .product-info {
    order: 2; /* Product info section comes second */
    margin-bottom: 20px; /* Add space below */
  }
  
  .product-seller {
    order: 3; /* Seller info section comes third */
    margin-bottom: 20px; /* Add space below */
  }
  
  .product-description {
    order: 4; /* Product description comes last */
  }
  
  .product-images, .product-info, .product-seller {
    padding: 10px; /* Reduce padding for smaller screens */
  }
  
  .product-name {
    font-size: 1.5rem; /* Adjust font size for mobile */
  }
  
  .section-title {
    font-size: 1rem; /* Adjust font size for section titles */
  }
  
  .alt-image {
    width: 50px; /* Adjust alternative image size */
    height: 50px;
  }
  
  .seller-title {
    font-size: 1.1rem; /* Adjust seller title size */
  }
  
  .seller-detail-item {
    font-size: 0.8rem; /* Adjust seller detail item size */
  }
}

@media (max-width: 480px) {
  .product-page {
    padding: 10px; /* Adjust padding for very small screens */
  }
  
  .product-name {
    font-size: 1.2rem; /* Adjust font size further for very small screens */
  }
  
  .section-title {
    font-size: 0.9rem; /* Adjust section title size */
  }
  
  .alt-image {
    width: 40px; /* Further adjust alternative image size */
    height: 40px;
  }
  
  .seller-title {
    font-size: 1rem; /* Further adjust seller title size */
  }
  
  .seller-detail-item {
    font-size: 0.7rem; /* Further adjust seller detail item size */
  }
}

