.product-card {
  text-align: left;
  width: 250px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  text-align: center;
  background-color: #fff;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-image {
  width: auto;
  height: 150px;
  object-fit: cover;
  margin-bottom: 10px;
}

.product-info-product-card {
  margin-bottom: 10px;
}

.product-name-product-card {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.brand-name {
  text-align: left;
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 5px;
}

.seller-info {
  text-align: left;
  font-size: 0.85rem;
  color: #777;
}

.view-product-btn,
.get-price-list-btn {
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  font-size: 0.9rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.view-product-btn {
  /* background-color: #f0f0f0;
  color: #333; */
  background-color: #4a47a3;
  color: #fff;
}

.get-price-list-btn {
  background-color: #4a47a3;
  color: #fff;
}


.alt-images-container {
  display: flex;
  align-items: center;
}

.scroll-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 10px;
}

.alt-images {
  display: flex;
  overflow: hidden;
  gap: 10px;
}

.alt-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
}

.alt-image.selected {
  opacity: 1;
  border: 2px solid #007bff;  /* Add a blue border to highlight the selected image */
}

.scroll-button:hover {
  background-color: #f0f0f0;
}

.scroll-button:focus {
  outline: none;
}
