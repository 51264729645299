.form {
    display: flex;
}

.form__input{
    flex: 1;
}

.form input {
    border: 1px solid #c5baba;
    padding: 6px;
    border-radius: 5px 0 0 5px;
    width: 100%;
}

.form input:focus {
    outline: none;
}

.form button {
    border: 1px solid #c5baba;
    /* background: #ccccccba; */
    padding: 6px 12px;
    border-radius: 0 5px 5px 0;
    border-left: none;
    transition: backgroud 2s;
}

.form button:hover {
    background: #ed5e02;
}

.search__main {
    width: 100%;
}