.App {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.Pages{
  width: 85vw;
  margin: 0 auto;
  margin-bottom: 5vh; 
}


@media (max-width: 768px) {
  .Pages{
    width: 85vw;
    margin: 0 auto;
    margin-bottom: 5vh; 
  }
}


@media (max-width: 414px) {
  .Pages{
    width: 85vw;
    margin: 0 auto;
    margin-bottom: 5vh; 
  }
}