 .Home-page-container {
  width: 85vw;
}

.home-page {
  margin: 0;
  padding: 0; 
}

.container {
  width: 100%; 
  max-width: 100%; 
  padding: 0; 
 
}
.badges {
  font-size: 0.50em;

  position: absolute;
}
.section1, .section2, .section3 {
  width: 100%;
  margin: 0; 
  padding: 0;
}

.section1 {
  display: flex;
  flex-wrap: wrap;
  /* height: 45vh;  */
  overflow: hidden;
  margin-top: 2vh;
}

.section1 .card {
  /* height: 100%; */
  text-align: left;
  padding-left: 2%;
  border: 1px solid #e9ecef;
  border-radius: 0.5em;
  background-color: #f8f9fa;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(33.333% - 2%);
  box-sizing: border-box;
}

.services {
  margin-top: 3vw;
  background-color: #f8f9fa;
}

.services-list {
  margin: 0 auto;
}

.services h2 {
  font-size: 2vw; 
  font-weight: bold;
}

.services ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0 auto;
}

.services li {
  font-size: 1.5vw;
  margin-bottom: 1vw;
  color: #212529;
}

.image-carousel .carousel-image {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #e9ecef;
}

.image-carousel img {
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.section2 {
  height: auto; 
  padding: 2vw;
}


.section2-title {
  font-weight: bold;
  margin-bottom: 2vw;
  text-align: left;
  font-size: 1.5rem;
  margin-top: revert;
}





.featured-product-card:hover {
  transform: scale(1.05);
}

.featured-product-image-container {
  text-align: center;
  margin-bottom: 10px;
}

.featured-product-image {
  width: 150px; 
  height: 150px; 
  object-fit: cover;
  margin-bottom: 10px;
}

.featured-product-title{
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.featured-button-group {
  display: flex;
  flex-direction: column;
  gap: 5px; 
}

.view-product-btn,
.get-price-list-btn {
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  font-size: 0.9rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.featured-view-product-btn {
  /* background-color: #f0f0f0;
  color: #333; */
  background-color: #4a47a3;
  color: #fff;
}

.featured-price-list-btn {
  background-color: #4a47a3;
  color: #fff;
}

.section3 {
  margin-top: 5vh;
  border-radius: 0.625em;
  background-color: whitesmoke;
  padding: 2vw;
  margin-bottom: 5vh;
}

.brand-logos-wrapper {
  overflow: hidden;
  position: relative;
}

.brand-logos {
  display: flex;
  white-space: nowrap;
  /* animation: scroll-left 10s linear infinite; */
}

.brand-logo {
  padding: 1vw;
  background-color: #e9ecef;
  border-radius: 0.3125em;
  text-align: center;
  flex: 0 0 auto;
  margin: 0 1vw;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@media (max-width: 768px) {
  .section1 {
    height: auto;
    overflow: hidden;
    margin-top: 2vh;
    display: flex;
    flex-direction: column-reverse; /* Change the order of elements */
    gap: 1vh;
  }

  .section1 .card {
    flex: 1 1 auto; /* Allow the card to take full width on smaller screens */
    text-align: left;
    padding-left: 5%;
    border: 1px solid #e9ecef;
    border-radius: 0.5em;
    background-color: #f8f9fa;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  }

  .image-carousel {
    width: 100%;  /* Carousel should take full width */
    margin-top: 0;
    margin-bottom: 1px;
  }

  .image-carousel .carousel-image {
    height: 200px; /* Adjust carousel height for mobile */
    width: 100%;
  }

  .services h2 {
    font-size: 4vw; /* Adjust font size for smaller screens */
  }

  .services li {
    font-size: 4vw; /* Adjust list item font size */
  }

  .badges {
    font-size: 0.5em;
    position: absolute;
  }
}

/* Base styles for the section */


.section2-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

/* Grid Layout for Products */
.product-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns for web */
  gap: 1rem;
  width: 100%;
}

.product-card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}



.featured-product-card:hover {
  transform: scale(1.05);
}

.product-image-container {
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
}

.product-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.product-title {
  font-size: rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.seller-info {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #555;
}

.seller-name, .seller-city {
  margin: 0.25rem 0;
}

.button-group {
  margin-top: 4rem;
  width: max-content
}

.btn-cus {
  width: 280px;
}

/* Mobile and tablet responsiveness */
@media (max-width: 768px) {
  .product-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for mobile/tablet */
    gap: 0.5rem;
    margin-left: -6px;
  }

 
  .product-title {
    font-size: 1rem;
  }

  .seller-info {
    font-size: 0.8rem;
  }
}

@media (max-width: 380px) {
  .product-grid {
    grid-template-columns: 1fr; /* 1 column for very small screens */
  }



  .product-title {
    font-size: 0.9rem;
  }
}




