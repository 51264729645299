/* section3.css */

/* Adjust the height of the carousel */
.custom-carousel .carousel-inner {
    height: 400px;  /* Set the height as desired (e.g., 400px) */
  }
  
  .custom-carousel .carousel-item {
    height: 100%; /* Ensure the carousel item takes the full height of the carousel */
  }
  
  .custom-carousel .carousel-item img {
    object-fit: cover; /* Ensure the images cover the area and maintain aspect ratio */
    width: 100%;
    height: 100%; /* Make sure the image fills the container */
  }
  
  /* Adjust height for mobile devices */
  @media (max-width: 768px) {
    .custom-carousel .carousel-inner {
      height: 300px; /* Adjust to a smaller height for mobile */
    }
  }
  