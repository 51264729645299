/* Footer.css */

.footer {
  background-color: whitesmoke; /* White background */
  color: #555555; /* Dark grey text */
  padding: 40px 20px;
  font-family: Arial, sans-serif;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #dddddd; /* Light grey border */
}

.footer-logo img {
  max-width: 180px;
  height: auto;
}

.footer-menu {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}

.footer-main-menu, .footer-quick-links {
  flex: 1;
  min-width: 220px;
}

.footer-main-menu h4, .footer-quick-links h4, .footer-contact h4, .footer-social h4 {
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: bold;
  color: #555555; /* Dark grey headings */
}

.footer-main-menu ul, .footer-quick-links ul {
  list-style-type: none;
  padding-left: 0;
}

.footer-main-menu li, .footer-quick-links li {
  margin-bottom: 10px;
}

.footer-main-menu a, .footer-quick-links a {
  color: #555555; /* Dark grey links */
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;
}

.footer-main-menu a:hover, .footer-quick-links a:hover {
  color: #00aced; /* Blue hover effect */
}

.footer-contact p {
  font-size: 14px;
  margin: 10px 0;
}

.footer-contact p svg {
  margin-right: 10px;
}

.footer-social .social-icons {
  display: flex;
  gap: 15px;
}

.footer-social .social-icons a {
  color: #555555; /* Dark grey icons */
  font-size: 24px;
  transition: color 0.3s;
}

.footer-social .social-icons a:hover {
  color: #00aced; /* Blue hover effect */
}

.footer-bottom {
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid #dddddd; /* Light grey border */
}

.footer-bottom p {
  margin: 10px 0;
  color: #555555; /* Dark grey text */
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .footer-top {
    flex-direction: column;
    align-items: center;
  }

  .footer-menu {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .footer-main-menu, .footer-quick-links, .footer-contact, .footer-social {
    text-align: center;
  }

  .footer-bottom {
    padding-top: 20px;
  }

  .footer-bottom-links {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}
