
.auth-box {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    width: 360px; 
    margin: 0 auto;
  }
  
  .login-header{
    text-align: center;
    align-items: center;
    margin-bottom: 3vh;
  }
  .login-header h2 {
    margin-bottom: 0 !important;
}

.login-header h6 {
    margin-top: 0 !important;
    /* margin-bottom: 0 !important; */
    opacity: 50%;
}

  .toggle-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .toggle-buttons button {
    width: 48%; 
    font-weight: bold;
  }
  
  button.btn-primary {
    background-color: #2c2e83;
    border-color: #2c2e83;
  }

  
  button.btn-light {
    background-color: #f0f0f0;
    border-color: #f0f0f0;
  }
  
  .input-group-custom .dropdown-toggle {
    background-color: #e0e0e0;
    border-color: #e0e0e0;
    font-size: 16px;
    height: 45px;
  }
  
  .input-group-custom input,
  .input-custom {
    height: 45px;
    font-size: 16px;
  }
  
  .input-group-custom .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
  }
  
  
  .btn-custom {
    font-size: 16px;
    height: 45px;
  }
  

  @media (max-width: 768px) {
    .auth-box {
      width: 100%; 
      padding: 20px;
    }
  }
  