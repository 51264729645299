.NavBar-container {
  width: 100%;
  background-color: #f8f9fA;
}

.LoginButton {
  background-color: #32317d;
  margin-left: 20px;
  color: white;
}

.custom-modal .modal-dialog {
  height: auto;
}

.custom-modal .modal-content {
  border: none;
  background-color: transparent;
}

.uppernavbar-container {
  width: 100%;
  background-color: #f8f9fA;
}

.UpperNavBar {
  width: 85vw;
  margin: 0 auto;
}

.Nav {
  display: flex; 
  align-items: center; 
  justify-content: flex-end; 
}

.Nav .nav-link {
  margin-right: 15px; 
}


.modal-body{
  align-items: center; 
}

@media (max-width: 1200px) {
  .UpperNavBar {
    width: 90vw; 
  }
}

@media (max-width: 768px) {
  .UpperNavBar {
    width: 95vw; 
  }

  .Nav {
    flex-direction: row; 
    flex-wrap: nowrap; 
  }

  .LoginButton {
    margin-left: 0; 
    margin-top: 10px; 
  }
}

@media (max-width: 480px) {
  .UpperNavBar {
    width: 100vw; 
  }
}

/* LowerNavBar Styles */
.lower-navbar {
  display: flex; /* Set as flex container */
  flex-direction: row; /* Align items horizontally */
  flex-wrap: wrap; /* Allow wrapping if needed */
  justify-content: flex-start; /* Align items to the start */
  background-color: grey;
  padding: 2px 5px;
}

.box-text {
  margin-left: 7vw;
  display: flex; 
  flex-wrap: nowrap; 
  justify-content: flex-start; /* Align items to the start */
}

.category-item {
  color: white;
  margin-right: 30px;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  transition: color 0.3s;
}

.category-item:hover {
  color: black;
}

.category-item.selected {
  color: black; 
  /* font-weight: bold; */
}

/* Dropdown Styles */
.subcategory-dropdown {
  position: absolute;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1; /* Ensure dropdown is above other elements */
  padding: 5px 0; /* Add some padding for the dropdown */
}

.subcategory-dropdown-item {
  width: 10vw;
  padding: 8px 16px; /* Spacing for each dropdown item */
  color: black; /* Text color for dropdown items */
  cursor: pointer; /* Cursor pointer for dropdown items */
}

.subcategory-dropdown-item:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}

/* Ensure the category-item has a relative position for dropdown */
.category-item {
  position: relative; /* Needed for absolute positioning of dropdown */
}

/* Responsive Styles for LowerNavBar */
@media (max-width: 768px) {
  .lower-navbar {
    flex-direction: row; /* Ensure items are in a row */
    justify-content: center; /* Center items horizontally */
    padding: 10px; /* Adjust padding for mobile */
  }

  .category-item {
    margin-right: 15px; /* Adjust margin for spacing */
    font-size: 14px; /* Slightly smaller font size for mobile */
  }
}

@media (max-width: 480px) {
  .category-item {
    font-size: 12px; /* Further reduce font size for smaller screens */
    margin-right: 10px; /* Adjust margin for spacing */
  }
  .UpperNavBar {
    width: 85vw;
    margin: 0 auto;
  }
  .ml-auto{
    display: flex;
    gap:4vw;
    flex-wrap: wrap;
    flex-direction: row;
  }
}