.privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.8;
  color: #333;
}

.privacy-policy-container h1 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #222;
}

.privacy-policy-container h2 {
  font-size: 1.5rem;
  margin-top: 20px;
  color: #444;
}

.privacy-policy-container p {
  font-size: 1rem;
  margin-bottom: 15px;
}

.privacy-policy-container ul {
  list-style-type: disc;
  margin-left: 20px;
}

.privacy-policy-container ul li {
  font-size: 1rem;
  margin-bottom: 10px;
}

.privacy-policy-container b {
  color: #555;
}
