.about-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .about-container p {
    line-height: 1.6;
    margin-bottom: 10px;
  }
  