.terms-container {
    padding: 20px;
    margin: 0 auto;
    max-width: 800px;
    line-height: 1.6;
    font-family: Arial, sans-serif;
  }
  
  .terms-container h1 {
    text-align: center;
    color: #333;
  }
  
  .terms-container h2 {
    color: #555;
    margin-top: 20px;
  }
  
  .terms-container p {
    color: #666;
  }
  
  .terms-container .footer-note {
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
  }
  