.status-icon {
    font-size: 5rem;
    margin-bottom: 1rem;
  }
  
  .status-icon.success {
    color: #28a745; /* Green color */
  }
  
  .status-icon.failed {
    color: #dc3545; /* Red color */
  }
  
  .text-center {
    text-align: center;
  }
  
  .modal-body h4 {
    font-weight: bold;
    color: #333;
  }
  
  .modal-body p {
    color: #666;
  }
  